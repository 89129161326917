import React from "react"

class Footer extends React.Component {
  render () {
    return (
      <ul className="nav">
        <li> Email:<a mailto="mathllctax@gmail.com">mathllctax@gmail.com</a></li>
        <li>|</li>
        <li> Fax: (503)455-5553 </li>
      </ul>
    );
  }
}

export default Footer